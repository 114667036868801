import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const originalPush = VueRouter.prototype.push
//修改原型对象中的push方法
VueRouter.prototype.push = function push(location) {
   return originalPush.call(this, location).catch(err => err)
}
const routes = [
  {
    path: '/',
    name: '/',
    component: () => import(/* webpackChunkName: "about" */ '../views/Index.vue'),
    meta: {
      title:'玛峰教育-首页'
    }
  },
  {
    path: '/about',
    name: 'About',
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue'),
    meta: {
      title:'玛峰教育-关于我们'
    }
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "about" */ '../views/login.vue'),
    meta: {
      title:'玛峰教育-登录'
    }
  },
  {
    path: '/index',
    name: 'Index',
    component: () => import(/* webpackChunkName: "about" */ '../views/Index.vue'),
    meta: {
      title:'玛峰教育-首页'
    }
  },
  {
    path: '/resetPassword',
    name: 'resetPassword',
    component: () => import(/* webpackChunkName: "about" */ '../views/ResetPassword.vue'),
    meta: {
      title:'玛峰教育-重置密码'
    }
  },
  {
    path: '/newsDetail',
    name: 'newsDetail',
    component: () => import(/* webpackChunkName: "about" */ '../views/newsDetail.vue'),
    meta: {
      title:'玛峰教育-新闻详情'
    }
  },
  {
    path: '/register',
    name: 'register',
    component: () => import(/* webpackChunkName: "about" */ '../views/Register.vue'),
    meta: {
      title:'玛峰教育-注册'
    }
  },
  {
    path: '/jp',
    name: 'jp',
    component: () => import(/* webpackChunkName: "about" */ '../views/jp.vue'),
    meta: {
      title:'玛峰教育-日语'
    }
  },
  {
    path: '/issIndex',
    name:'issIndex',
    component: () => import(/* webpackChunkName: "about" */ '../views/IssIndex.vue'),
    meta: {
      title:'玛峰教育-Iss系统'
    }
  },
  {
    path: '/userCenter',
    name:'userCenter',
    component: () => import(/* webpackChunkName: "about" */ '../views/userCenter.vue'),
    meta: {
      title:'玛峰教育-用户中心'
    }
  },
  {
    path: '/contactUs',
    name: 'contactUs',
    component: () => import(/* webpackChunkName: "about" */ '../views/contactUs.vue'),
    meta: {
      title:'玛峰教育-联系我们'
    }
  },
  {
    path: '/newsList',
    name: 'newsList',
    component: () => import('../views/newsList.vue'),
    meta: {
      title:'玛峰教育-精选报告'
    }
  }
]

const router = new VueRouter({
  routes
})

export default router
