<template>
  <div id="app">
   
    <router-view  :key="key"/>
  </div>
</template>

<script>
export default {
  data () {
    return {
      
    }
    
  },
  computed: {
    key() {
       return this.$route.fullPath;
    }
  }
}
</script>


<style>

.flex-wrap-st{
    display: flex;
}
body{
  min-width: 1255px;
}

.justify-ctc{
    justify-content: center;
}
.justify-sad{
    justify-content: space-around;
}
.justify-btw{
    justify-content: space-between;
}
.align-ctc{
    align-items: center;
}
.align-fed{
  align-items: flex-end;
}
.h100{
    height: 100%;
}
*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body{
  font-family: "Microsoft YaHei", 微软雅黑, "Open Sans", PingFangSC-Light, Arial, "Hiragino Sans GB", STHeiti, "WenQuanYi Micro Hei", SimSun, sans-serif;
}
</style>
