import request from '../utils/request'

export function Login (t1, t2) {
    const data = {
        username: t1,
        password: t2
    }
    return request({
      url: `/website/user/login`,
      method: 'post',
      data
    })
  }
  