import axios from 'axios'
// import { Message, MessageBox } from 'element-ui'
// import { Message } from 'view-design'
import store from '@/store'
import { getToken, removeToken } from '../utils/auth'

console.log(process.env)
const service = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL,
  timeout: 5000,
  withCredentials: true
})
let self = this

service.interceptors.request.use(config => {
  config.headers['App-Key'] = 'e8c7b457c40c1eb0'
//   config.headers['Content-Type'] = 'application/x-www-form-urlencoded'
  if (getToken()) {
    config.headers['Access-Token'] = getToken()
  }
  let params = new URLSearchParams()
  const data = config.data
  for (let name in data) {
    params.append(name, data[name])
  }
  config.data = params.toString()
  return config
}, error => {
  Promise.reject(error)
})
// 每次发请求都会验证登录是否过期
service.interceptors.response.use(response => {
  switch (response.data.code) {
    case 201:
    //   Message.error('登录已过期')
    //   removeToken()
    //   location.reload()


    //   MessageBox.confirm('登录已过期，请重新登录', '登录已过期', {
    //     confirmButtonText: '确定',
    //     cancelButtonText: '取消',
    //     type: 'warning'
    //   }).then(() => {
    //     store.dispatch('FedLogout').then(() => {
    //       location.reload()
    //     })
    //   })
    //   break
    // case 500100:
    //   Message.error('服务器错误，请稍后尝试')
    //   break
  }
return response
}, error => {
//   Message({
//     // message: error.statusText,
//     message: '服务器错误，请稍后尝试',
//     type: 'error',
//     duration: 5000
//   })
return Promise.reject(error)
})
export default service
