import router from './router'
import NProgress from 'nprogress' // progress bar
import 'nprogress/nprogress.css' // progress bar style
import { getToken } from '@/utils/auth' // get token from cookie

router.beforeEach(async (to, from, next) => {
  if(to.meta.title) {
    document.title = to.meta.title
  }
  NProgress.start()
  const hasToken = getToken()

  if (hasToken) {
    if(to.path === '/login') {
      if (to.meta.title) {
        document.title = to.meta.title
      }
      next('/index')
      NProgress.done()
    } else {

      if (to.meta.title) {
        document.title = to.meta.title
      }
      next()  
      NProgress.done()
    }
    
  } else {

    if(
      to.path == '/index' 
      || to.path == '/register' 
      || to.path == '/resetPassword'
      || to.path == '/contactUs'
      || to.path == '/newsList'
      || to.path == '/jp'
      || to.path == '/issIndex'
      || to.path == '/'
      ) {
        if (to.meta.title) {
          document.title = to.meta.title
        }
      next()
      NProgress.done()
    } else {
      if(to.path === '/login') {
        if (to.meta.title) {
          document.title = to.meta.title
        }
        next()
      } else {
        if (to.meta.title) {
          document.title = to.meta.title
        }
        next('/login')
        NProgress.done()
      }
      
    }
    
  
  }

})
router.afterEach(() => {
  // finish progress bar
  NProgress.done()
})
