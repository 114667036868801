
import { Login } from '@/api/login'
import { getToken, setToken } from '@/utils/auth'
import { MessageBox } from 'element-ui';
import { Message } from 'element-ui';

const user = {
  state: {
    token: getToken(),
    userinfo: sessionStorage.getItem('userinfo')
  },
  mutations: {
    SET_USERINFO: (state, userinfo) => {
      state.name = name
      sessionStorage.setItem('userinfo', userinfo)
    }
  },
  actions: {
    LoginByUsername({commit}, loginInfo) {
      return new Promise((resolve, reject) => {
        Login(loginInfo.mobile, loginInfo.password).then(res => {
          let data = res.data.data
          if (data.error === 0) {
            const userInfo = JSON.stringify(data.data)
            commit('SET_USERINFO', userInfo)
            setToken(data.data.token)
            
            resolve(res)
          } else {
            resolve(res)
            return false
          }
        }).catch(error => { reject(error) })
      })
    }
  }
}

export default user
